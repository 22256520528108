$(document).ready(function () {
    //INTERNA
    //CORES

    $(".cor-externa").click(function () {
        $(".cor-externa").removeClass("active");
        $(this).addClass("active");

        var assets = $(this).data("assets");
        var image = $(this).data("image");
        var nomeCor = $(this).data("nome");

        $(".image-color img").attr("src", assets + image + ".avif");

        $(this).closest(".container-controles").find(".nome_cor").text(nomeCor);
    });

    //Acabamento Interno
    $(".cor-acabamento-interno").click(function () {
        $(".cor-acabamento-interno").removeClass("active");
        $(this).addClass("active");

        var assets = $(this).data("assets");
        var image = $(this).data("image");
        var nomeCor = $(this).data("nome");

        $(".imagem-acabamento-interno img").attr("src", assets + image + ".avif");

        $(this).closest(".container-controles").find(".nome_cor").text(nomeCor);
    });

    //controle-carroceria
    $(".controle-carroceria .controle").click(function () {
        $(".controle-carroceria .controle").removeClass("active");
        $(this).addClass("active");

        var image = $(this).data("image");
        var assets = $(this).data("assets");
        $(".image-carroceria img").attr("src", assets + image + ".avif");
    });

    //controle-tecnologia
    $(".controle-tecnologia .controle").click(function () {
        $(".controle-tecnologia .controle").removeClass("active");
        $(this).addClass("active");

        var image = $(this).data("image");
        var assets = $(this).data("assets");
        $(".image-tecnologia img").attr("src", assets + image + ".avif");
    });

    //controle-cockpit
    $(".controle-cockpit .controle").click(function () {
        $(".controle-cockpit .controle").removeClass("active");
        $(this).addClass("active");

        var image = $(this).data("image");
        var assets = $(this).data("assets");
        $(".image-cockpit img").attr("src", assets + image + ".avif");
    });

    $(".collapse-form").click(function () {
        var target = $(this).data("target-collapse");

        $(target).collapse("toggle");

        $(this).find("i").toggleClass("fa-chevron-down fa-chevron-up");
    });

    $(".js-click-event").on("click", function () {
        sendGAEvent("click", $(this).data("category"), $(this).data("label"));
    });

    $("#btn-filtro").click(function () {
        $(".filtro").toggle("slow", function () {});
    });

    $(".btn-menu-mobile").on("click", function () {
        if ($(this).hasClass("open")) {
            closeMenu();
            history.back();
        } else {
            openMenu();
            window.history.pushState({ menu: "open" }, "Menu", "#menu");
        }
    });

    $("#dropdown-top-menu").click(function () {
        $(this).find(".menu-top-content_nav-dropdown").toggle();
    });
});

$(window).on("load", function () {
    // EVENTO DE VISUALIZAÇÃO DE PRODUTO DO FACEBOOK --------------------

    if (typeof fbq !== "undefined" && window.location.href.split("/").indexOf("estoque") > 0) {
        fbq("track", "ViewContent");
    }

    // FIM EVENTO DE VISUALIZAÇÃO DE PRODUTO DO FACEBOOK --------------------

    // EVENTO SCROLL

    $(document).scroll(function () {
        if ($(window).width() > 768) {
            var form_busca = document.querySelector("#form_busca");

            if (form_busca) {
                if ($(document).scrollTop() > 473) {
                    $("#form_busca").addClass("form_busca_fixed");
                    $(".header-head-bar").addClass("d-none");
                } else {
                    $("#form_busca").removeClass("form_busca_fixed");
                    $(".header-head-bar").removeClass("d-none");
                }
            }
        }
    });

    // FIM EVENTO SCROLL
});

//alterar mapa do rodapé versão mobile
$("#selecionar-mapa-mobile").change(function () {
    $(".map-content").removeClass("active");
    $(".map-content.map-content-" + $(this).val()).addClass("active");
});

$(".modal").on("show.bs.modal", function (e) {
    $("#mobile-footer-menu").hide();
});
$(".modal").on("hide.bs.modal", function (e) {
    if ($(window).width() < 768) {
        $("#mobile-footer-menu").fadeIn();
    }
});

$(".lojas-mapa .js-showmap").on("click", function () {
    $(".js-showmap").removeClass("active");
    $(this).addClass("active");
    id = $(this).data("map_id");
    $(".map-content").removeClass("active");
    $(".map-content.map-content-" + id).addClass("active");
    return false;
});
$(".slider-desktop").on("init", function (slick) {
    $(".slick-prev.slick-arrow").addClass("seta-esquerda").html('<img src="assets/img/Button-Previous.png" alt="Seta Esquerda">');
    $(".slick-next.slick-arrow").addClass("seta-direita").html('<img src="assets/img/Button-Next.png" alt="Seta direita">');
});

$(".fotos-do-carro").on("init", function (slick) {
    $(".slick-prev.slick-arrow").addClass("seta-esquerda").html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $(".slick-next.slick-arrow").addClass("seta-direita").html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
});

$("#carousel-dif").on("init", function (slick) {
    $(".slick-prev.slick-arrow").addClass("seta-esquerda").html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $(".slick-next.slick-arrow").addClass("seta-direita").html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
});

$(".slider-destaques").on("init", function (slick) {
    $(".slick-prev.slick-arrow").addClass("seta-esquerda").html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $(".slick-next.slick-arrow").addClass("seta-direita").html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
});

$("body").on("init", ".slider-desktop", function () {
    $(".slider-desktop").css("visibility", "visible");
});

$(".slider-desktop").slick({
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
});

$("body").on("init", ".slider-mobile", function () {
    $(".slider-mobile").css("visibility", "visible");
});

$(".slider-mobile").slick({
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
});

$(".fotos-do-carro").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    fade: false,
    asNavFor: ".galeria-thumb",
});

$(".galeria-thumb").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: ".fotos-do-carro",
    arrows: false,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    verticalSwiping: true,
});
$("#carousel-dif").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    verticalSwiping: true,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
});

$("#slider-destaques").slick({
    lazyLoad: "ondemand",
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    verticalSwiping: true,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
});

//Visualizador de video
$(".popup-youtube").magnificPopup({
    type: "iframe",
    iframe: {
        markup:
            '<div class="mfp-iframe-scaler">' +
            '<div class="mfp-close"></div>' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            '<a href="" class="btn-medium btn-full btn-main-color">Mais informações</a>' +
            "</div>",
        srcAction: ["iframe_src"],
    },
    callbacks: {
        markupParse: function (template, values, item) {
            template.find("a").attr("href", $(item.el[0]).data("car_url"));
            console.log(template, values, item);
        },
    },
});

$(".btn-mais-filtro").click(function () {
    if ($(this).hasClass("mais")) {
        $(this).removeClass("mais");
        $(this).addClass("menos");
        $(this).children("span").html("Menos filtros");
    } else {
        $(this).addClass("mais");
        $(this).removeClass("menos");
        $(this).children("span").html("Mais filtros");
    }
});

$("body").on("change", ".select-marca", function () {
    var id_marca = $(this).val();
    var $this = $(this);
    carregarModelos(id_marca, $this);
});

if ($(".select-marca").length > 0) {
    $(".select-marca").each(function (index) {
        var id_marca = $(this).val();
        var $this = $(this);
        if ($this.closest("form").find(".select-modelo").children().length <= 1) {
            carregarModelos(id_marca, $this);
        }
    });
}

$(".form-ajax").ajaxForm({
    dataType: "json",
    beforeSubmit: function (arr, $form, options) {
        $(".submit-action").attr("disabled", "disabled");
        startLoading();
    },
    success: function (json, status, xhr, form) {
        var id_form = form[0].id;

        if (json.erro != "") {
            alertify.minimalDialog("Erro", json.erro);
            sendGAEvent("generate_lead", "lead_error", json.erro);
        } else {
            sendGAEvent("generate_lead", "lead", id_form);
            adtracker();
            alertify.minimalDialog("Sucesso", json.sucesso);
            $("#" + id_form)[0].reset();
        }
    },
    error: function () {
        alertify.minimalDialog("Erro", "Erro ao processar formulário.<br>Tente novamente mais tarde.");
    },
    complete: function () {
        $(".submit-action").removeAttr("disabled");
        stopLoading();
    },
});

$(".busca li a").click(function () {
    $(this).toggleClass("busca-estoque-ativa");
    $(this).parent().find(".sub-opcoes").slideToggle();
});

$(".ano_min_refresh, .ano_max_refresh").on("change", function () {
    //refreshPage($(this).attr('name'), $(this).val());
});
$(".limit_refresh").on("change", function () {
    refreshPage($(this).attr("name"), $(this).val());
});

$("body").on("click", ".js-modal-click-action", function () {
    var assunto = $(this).data("id_assunto");
    var seminovo = $(this).data("id_seminovo");
    console.log(assunto);
    if (assunto) {
        $(".js-form-recebe-dados").find(".id_assunto").val(assunto);
    }
    if (seminovo) {
        $(".js-form-recebe-dados").find(".id_seminovo").val(seminovo);
    }
});

$(".mask--ano").inputmask("9999");
$(".mask--data").inputmask("99/99/9999");
$(".mask--cep").inputmask("99999-999");
$(".mask--cpf").inputmask("999.999.999-99");
$(".mask--cnpj").inputmask("99.999.999/9999-99");
$(".mask--placa").inputmask("aaa-9*99");
$(".mask--phone").inputmask({
    mask: ["(99)9999-9999", "(99)99999-9999"],
    keepStatic: true,
});
$(".mask--money").inputmask("currency", {
    autoUnmask: true,
    radixPoint: ",",
    groupSeparator: ".",
    allowMinus: false,
    prefix: "",
    digits: 2,
    digitsOptional: false,
    rightAlign: false,
    unmaskAsNumber: true,
    removeMaskOnSubmit: true,
    onUnMask: function (maskedValue, unmaskedValue, opts) {
        if (unmaskedValue === "" && opts.nullable === true) {
            return unmaskedValue;
        }
        var processValue = maskedValue.replace(opts.prefix, "");
        processValue = processValue.replace(opts.suffix, "");
        processValue = processValue.replace(new RegExp(Inputmask.escapeRegex(opts.groupSeparator), "g"), "");
        if (opts.radixPoint !== "" && processValue.indexOf(opts.radixPoint) !== -1)
            processValue = processValue.replace(Inputmask.escapeRegex.call(this, opts.radixPoint), ".");

        return processValue;
    },
});
//$(".mask--ano").mask("9999");
//$(".mask--data").mask("99/99/9999");
//$(".mask--cep").mask("99999-999");
//$(".mask--cpf").mask("999.999.999-99");
//$(".mask--cnpj").mask("99.999.999/9999-99");
//$(".mask--placa").mask("aaa-9999");
//$('.mask--phone')
//.mask("(99)9999-9999?9", { placeholder: "" })
//.blur(function (event) {
//  var target, phone, element;
//  target = (event.currentTarget) ? event.currentTarget : event.srcElement;
//  phone = target.value.replace(/\D/g, '');
//  element = $(target);
//  element.unmask();
//  if(phone.length > 10) {
//    element.mask("(99)99999-999?9", { placeholder: "" });
//  } else {
//    element.mask("(99)9999-9999?9", { placeholder: "" });
//  }
//});

//Adicionar clique do banner
$(".btn-banner-click").on("click", function (event) {
    var id_banner = $(this).find("img").data("id_banner");
    clickBanner(id_banner);
});

$("body").on("click", ".js-modal-click-action", function () {
    var assunto = $(this).data("id_assunto");
    var seminovo = $(this).data("id_seminovo");
    var banner = $(this).data("id_banner");
    console.log(assunto);
    if (assunto) {
        $(".js-form-recebe-dados").find(".id_assunto").val(assunto);
    }
    if (seminovo) {
        $(".js-form-recebe-dados").find(".id_seminovo").val(seminovo);
    }
    if (banner) {
        $(".js-form-recebe-dados").find(".id_banner").val(banner);
    }
});

$(".botao-tenho-interesse-lead").on("click", function () {
    $("html").animate({ scrollTop: $("#cta-interesse").offset().top }, 800);
    $(this).fadeOut();
    return false;
});
if ($(".botao-tenho-interesse-lead").length > 0) {
    $(window).on("scroll", function () {
        pageOffset = window.pageYOffset;
        ctaOffsetTop = parseInt($("#cta-interesse").offset().top);
        ctaOffsetBottom = parseInt($("#cta-interesse").height()) + ctaOffsetTop;
        if (pageOffset >= ctaOffsetTop - 300 && pageOffset < ctaOffsetBottom - 400) {
            $(".botao-tenho-interesse-lead").fadeOut();
        } else {
            $(".botao-tenho-interesse-lead").fadeIn();
        }
    });
}

$(".menu-overlay").on("click", function () {
    closeMenu();
    history.back();
});

//Ajuste estoque sem recarregar
$(".form-estoque").submit(function () {
    $(this)
        .find(":input")
        .filter(function () {
            return !this.value;
        })
        .attr("disabled", true);

    return true;
});

function atualizarFiltro(element = null, campo = "", formulario = null) {
    if (!formulario) {
        var formularioPai = $(element).closest("form");
    } else {
        var formularioPai = formulario;
    }

    if (campo == "marca") {
        var allItems = document.querySelectorAll(".item-marcas");

        allItems.forEach(function (item) {
            item.classList.remove("selected");
        });

        if (element !== undefined && element !== null) {
            element.classList.add("selected");
        }
    }

    var campoLimparFiltro = formularioPai.find(".btn-limpar-filtro");

    var campoMarca = formularioPai.find(".item-marcas.selected");
    var campoMarca_value = campoMarca.data("marca_id") ? campoMarca.data("marca_id") : null;
    var campoMarcaSelect = formularioPai.find(".select_marca");

    var campoValor = formularioPai.find(".select-valor");
    var checkboxesValor = campoValor.find('input[name="valor"]:checked');
    var campoValor_value = checkboxesValor.val();

    var campoModelo = formularioPai.find(".select-modelo");
    var checkboxesModelo = campoModelo.find('input[name="id_modelo[]"]:checked');
    var campoModelo_value = [];
    checkboxesModelo.toArray().forEach(function (checkbox) {
        campoModelo_value.push(checkbox.value);
    });

    var campoLoja = formularioPai.find(".select-loja");
    var checkboxesLoja = campoLoja.find('input[name="id_loja[]"]:checked');
    var campoLoja_value = [];
    checkboxesLoja.toArray().forEach(function (checkbox) {
        campoLoja_value.push(checkbox.value);
    });

    var campoCambio = formularioPai.find(".select-cambio");
    var checkboxesCambio = campoCambio.find('input[name="id_cambio[]"]:checked');
    var campoCambio_value = [];
    checkboxesCambio.toArray().forEach(function (checkbox) {
        campoCambio_value.push(checkbox.value);
    });

    var campoCombustivel = formularioPai.find(".select-combustivel");
    var checkboxesCombustivel = campoCombustivel.find('input[name="id_combustivel[]"]:checked');
    var campoCombustivel_value = [];
    checkboxesCombustivel.toArray().forEach(function (checkbox) {
        campoCombustivel_value.push(checkbox.value);
    });

    var campoAnoMax = 0;
    var campoANoMin = 0;

    var valorMin = 0;
    var valorMax = 0;

    switch (campoValor_value) {
        case "1":
            valorMin = 0;
            valorMax = 40000;
            break;
        case "2":
            valorMin = 40000;
            valorMax = 50000;
            break;
        case "3":
            valorMin = 50000;
            valorMax = 70000;
            break;
        case "4":
            valorMin = 70000;
            valorMax = 100000;
            break;
        case "5":
            valorMin = 100000;
            valorMax = 120000;
            break;
        case "6":
            valorMin = 120000;
            valorMax = 150000;
            break;
        case "7":
            valorMin = 150000;
            valorMax = 0;
            break;
    }

    if (campo == "marca") {
        campoMarcaSelect.val(campoMarca_value);
    }

    if (campo != "ano") {
        atualizarAno(campoMarca_value, campoModelo_value, valorMin, valorMax, campoCambio_value, campoCombustivel_value, campoLoja_value);
    } else {
        campoAnoMax = formularioPai.find(".anoveiculo")[0].noUiSlider.get()[1];
        campoANoMin = formularioPai.find(".anoveiculo")[0].noUiSlider.get()[0];
    }

    if (campo != "valor") {
        atualizarValor(campoValor, campoMarca_value, campoModelo_value, campoANoMin, campoAnoMax, campoCambio_value, campoCombustivel_value, campoLoja_value);
    }

    if (campo != "cambio") {
        atualizarFiltroCambio(
            campoCambio,
            campoMarca_value,
            campoModelo_value,
            campoANoMin,
            campoAnoMax,
            valorMin,
            valorMax,
            campoValor_value,
            campoCombustivel_value,
            campoLoja_value
        );
    }

    if (campo != "combustivel") {
        atualizarFiltroCombustivel(
            campoCombustivel,
            campoMarca_value,
            campoModelo_value,
            campoANoMin,
            campoAnoMax,
            valorMin,
            valorMax,
            campoValor_value,
            campoCambio_value,
            campoLoja_value
        );
    }

    if (campo != "loja") {
        atualizarFiltroLoja(
            campoLoja,
            campoMarca_value,
            campoModelo_value,
            campoANoMin,
            campoAnoMax,
            valorMin,
            valorMax,
            campoValor_value,
            campoCambio_value,
            campoCombustivel_value
        );
    }

    if (campo != "modelo" && campoMarca_value != null) {
        atualizarFiltroModelo(
            campoModelo,
            campoMarca_value,
            campoANoMin,
            campoAnoMax,
            valorMin,
            valorMax,
            campoCambio_value,
            campoCombustivel_value,
            campoLoja_value
        );
    }

    if (campo != "marca") {
        atualizarFiltroMarca(campoMarca, campoANoMin, campoAnoMax, valorMin, valorMax, campoCambio_value, campoCombustivel_value, campoLoja_value);
    }

    if (
        campoMarca_value == null &&
        campoModelo_value.length == 0 &&
        campoANoMin == 0 &&
        campoAnoMax == 0 &&
        valorMin == 0 &&
        valorMax == 0 &&
        campoCambio_value.length == 0 &&
        campoCombustivel_value.length == 0 &&
        campoLoja_value.length == 0
    ) {
        campoLimparFiltro.hide();
    } else {
        campoLimparFiltro.show();
    }
}

function verificarQuerys() {
    querys = getQueryParams(window.location.href, ["id_marca", "id_modelo[]", "valor", "id_cambio[]", "id_combustivel[]", "id_loja[]"]);

    if (querys["id_marca"] || querys["id_modelo[]"] || querys["valor"] || querys["id_cambio[]"] || querys["id_combustivel[]"] || querys["id_loja[]"]) {
        return true;
    }
}

function atualizarFiltroAno(element, campo) {
    $.ajax({
        type: "GET",
        data: { id_modelo: element.value },
        url: root_rel + "getData/getanomodelo",
        success: function (response) {
            if (response.maior_ano_modelo > 0 && response.menor_ano_modelo > 0) {
                if (response.menor_ano_modelo == response.maior_ano_modelo) {
                    response.maior_ano_modelo = +response.maior_ano_modelo + 1;
                }

                response.menor_ano_modelo = parseInt(response.menor_ano_modelo);
                response.maior_ano_modelo = parseInt(response.maior_ano_modelo);

                initSlideAnoVeiculo(response.menor_ano_modelo, response.maior_ano_modelo, 0, 0);
            }
        },
    });
}

function atualizarValor(
    element,
    id_marca = null,
    id_modelo = array(),
    ano_min = null,
    ano_max = null,
    id_cambio = array(),
    id_combustivel = array(),
    id_loja = array()
) {
    $.ajax({
        type: "GET",
        data: {
            id_marca: id_marca,
            id_modelo: id_modelo.length > 0 ? id_modelo.join(",") : "",
            ano_min: ano_min,
            ano_max: ano_max,
            id_cambio: id_cambio.length > 0 ? id_cambio.join(",") : "",
            id_combustivel: id_combustivel.length > 0 ? id_combustivel.join(",") : "",
            id_loja: id_loja.length > 0 ? id_loja.join(",") : "",
        },
        url: root_rel + "getData/getFaixaValor",
        success: function (response) {
            selecionados = apagaLi(element, "valor");

            const queryParams = getQueryParams(window.location.href, ["valor"]);
            selecionadosQuery = queryParams["valor"] ? queryParams["valor"] : [];

            if (response.menor_valor <= 40000) {
                if (!(selecionados.indexOf("1") > -1)) {
                    let checked = selecionadosQuery.indexOf("1") > -1 ? 1 : 0;
                    element.append(novoLi(1, "Até R$ 40.000", "valor", "radio", "valor", element, checked));
                }
            }
            if ((response.menor_valor >= 40000 && response.menor_valor <= 50000) || (response.maior_valor >= 40000 && response.maior_valor <= 50000)) {
                if (!(selecionados.indexOf("2") > -1)) {
                    let checked = selecionadosQuery.indexOf("2") > -1 ? 1 : 0;
                    element.append(novoLi(2, "De R$ 40.000 até R$ 50.000", "valor", "radio", "valor", element, checked));
                }
            }
            if ((response.menor_valor >= 50000 && response.menor_valor <= 70000) || (response.maior_valor >= 50000 && response.maior_valor <= 70000)) {
                if (!(selecionados.indexOf("3") > -1)) {
                    let checked = selecionadosQuery.indexOf("3") > -1 ? 1 : 0;
                    element.append(novoLi(3, "De R$ 50.000 até R$ 70.000", "valor", "radio", "valor", element, checked));
                }
            }
            if ((response.menor_valor >= 70000 && response.menor_valor <= 100000) || (response.maior_valor >= 70000 && response.maior_valor <= 100000)) {
                if (!(selecionados.indexOf("4") > -1)) {
                    let checked = selecionadosQuery.indexOf("4") > -1 ? 1 : 0;
                    element.append(novoLi(4, "De R$ 70.000 até R$ 100.000", "valor", "radio", "valor", element, checked));
                }
            }
            if ((response.menor_valor >= 100000 && response.menor_valor <= 120000) || (response.maior_valor >= 100000 && response.maior_valor <= 120000)) {
                if (!(selecionados.indexOf("5") > -1)) {
                    let checked = selecionadosQuery.indexOf("5") > -1 ? 1 : 0;
                    element.append(novoLi(5, "De R$ 100.000 até R$ 120.000", "valor", "radio", "valor", element, checked));
                }
            }
            if ((response.menor_valor >= 120000 && response.menor_valor <= 150000) || (response.maior_valor >= 120000 && response.maior_valor <= 150000)) {
                if (!(selecionados.indexOf("6") > -1)) {
                    let checked = selecionadosQuery.indexOf("6") > -1 ? 1 : 0;
                    element.append(novoLi(6, "De R$ 120.000 até R$ 150.000", "valor", "radio", "valor", element, checked));
                }
            }
            if (response.maior_valor >= 150000) {
                if (!(selecionados.indexOf("7") > -1)) {
                    let checked = selecionadosQuery.indexOf("7") > -1 ? 1 : 0;
                    element.append(novoLi(7, "Acima de R$ 150.000", "valor", "radio", "valor", element, checked));
                }
            }
        },
    });
}

function atualizarAno(
    id_marca = null,
    id_modelo = array(),
    valor_min = null,
    valor_max = null,
    id_cambio = array(),
    id_combustivel = array(),
    id_loja = array()
) {
    $.ajax({
        type: "GET",
        data: {
            id_marca: id_marca,
            id_modelo: id_modelo.length > 0 ? id_modelo.join(",") : "",
            valor_min: valor_min,
            valor_max: valor_max,
            id_cambio: id_cambio.length > 0 ? id_modelo.join(",") : "",
            id_combustivel: id_combustivel.length > 0 ? id_modelo.join(",") : "",
            id_loja: id_loja.length > 0 ? id_modelo.join(",") : "",
        },
        url: root_rel + "getData/getFaixaAno",
        success: function (response) {
            if (response.maior_ano > 0 && response.menor_ano > 0) {
                if (response.menor_ano == response.maior_ano) {
                    response.maior_ano = +response.maior_ano + 1;
                }

                response.menor_ano = parseInt(response.menor_ano);
                response.maior_ano = parseInt(response.maior_ano);

                initSlideAnoVeiculo(response.menor_ano, response.maior_ano, 0, 0);
            }
        },
    });
}

function atualizarFiltroCambio(
    element,
    id_marca = null,
    id_modelo = array(),
    ano_min = null,
    ano_max = null,
    valor_min = null,
    valor_max = null,
    id_valor = null,
    id_combustivel = array(),
    id_loja = array()
) {
    $.ajax({
        type: "GET",
        data: {
            id_marca: id_marca,
            id_modelo: id_modelo.length > 0 ? id_modelo.join(",") : "",
            ano_min: ano_min,
            ano_max: ano_max,
            valor_min: valor_min,
            valor_max: valor_max,
            id_valor: id_valor,
            id_combustivel: id_combustivel.length > 0 ? id_combustivel.join(",") : "",
            id_loja: id_loja.length > 0 ? id_loja.join(",") : "",
        },
        url: root_rel + "getData/getCambio",
        success: function (response) {
            selecionados = apagaLi(element, "id_cambio[]");

            const queryParams = getQueryParams(window.location.href, ["id_cambio[]"]);
            selecionadosQuery = queryParams["id_cambio[]"] ? queryParams["id_cambio[]"] : [];

            if (response.length > 0) {
                $.each(response, function (key, value) {
                    //verificar de o value.id está no array selecionados
                    if (!(selecionados.indexOf(value.id_cambio) > -1)) {
                        let checked = selecionadosQuery.indexOf(value.id_cambio) > -1 ? 1 : 0;
                        element.append(novoLi(value.id_cambio, value.descricao, "cambio", "checkbox", "id_cambio[]", element, checked));
                    }
                });
            }
        },
    });
}

function atualizarFiltroCombustivel(
    element,
    id_marca = null,
    id_modelo = array(),
    ano_min = null,
    ano_max = null,
    valor_min = null,
    valor_max = null,
    id_valor = null,
    id_cambio = array(),
    id_loja = array()
) {
    $.ajax({
        type: "GET",
        data: {
            id_marca: id_marca,
            id_modelo: id_modelo.length > 0 ? id_modelo.join(",") : "",
            ano_min: ano_min,
            ano_max: ano_max,
            valor_min: valor_min,
            valor_max: valor_max,
            id_valor: id_valor,
            id_cambio: id_cambio.length > 0 ? id_cambio.join(",") : "",
            id_loja: id_loja.length > 0 ? id_loja.join(",") : "",
        },
        url: root_rel + "getData/getCombustiveis",
        success: function (response) {
            selecionados = apagaLi(element, "id_combustivel[]");

            const queryParams = getQueryParams(window.location.href, ["id_combustivel[]"]);
            selecionadosQuery = queryParams["id_combustivel[]"] ? queryParams["id_combustivel[]"] : [];

            if (response.length > 0) {
                $.each(response, function (key, value) {
                    if (!(selecionados.indexOf(value.id_combustivel) > -1)) {
                        let checked = selecionadosQuery.indexOf(value.id_combustivel) > -1 ? 1 : 0;
                        element.append(novoLi(value.id_combustivel, value.descricao, "combustivel", "checkbox", "id_combustivel[]", element, checked));
                    }
                });
            }
        },
    });
}

function atualizarFiltroLoja(
    element,
    id_marca = null,
    id_modelo = array(),
    ano_min = null,
    ano_max = null,
    valor_min = null,
    valor_max = null,
    id_valor = null,
    id_cambio = array(),
    id_combustivel = array()
) {
    $.ajax({
        type: "GET",
        data: {
            id_marca: id_marca,
            id_modelo: id_modelo.length > 0 ? id_modelo.join(",") : "",
            ano_min: ano_min,
            ano_max: ano_max,
            valor_min: valor_min,
            valor_max: valor_max,
            id_valor: id_valor,
            id_cambio: id_cambio.length > 0 ? id_cambio.join(",") : "",
            id_combustivel: id_combustivel.length > 0 ? id_combustivel.join(",") : "",
        },
        url: root_rel + "getData/getLoja",
        success: function (response) {
            selecionados = apagaLi(element, "id_loja[]");

            const queryParams = getQueryParams(window.location.href, ["id_loja[]"]);
            selecionadosQuery = queryParams["id_loja[]"] ? queryParams["id_loja[]"] : [];

            if (response.length > 0) {
                $.each(response, function (key, value) {
                    if (!(selecionados.indexOf(value.id_loja) > -1)) {
                        let checked = selecionadosQuery.indexOf(value.id_loja) > -1 ? 1 : 0;
                        element.append(novoLi(value.id_loja, value.descricao, "loja", "checkbox", "id_loja[]", element, checked));
                    }
                });
            }
        },
    });
}

function atualizarFiltroModelo(
    element,
    id_marca = null,
    ano_min = null,
    ano_max = null,
    valor_min = null,
    valor_max = null,
    id_cambio = array(),
    id_combustivel = array(),
    id_loja = array()
) {
    $.ajax({
        type: "GET",
        data: {
            id_marca: id_marca,
            ano_min: ano_min,
            ano_max: ano_max,
            valor_min: valor_min,
            valor_max: valor_max,
            id_cambio: id_cambio.length > 0 ? id_cambio.join(",") : "",
            id_combustivel: id_combustivel.length > 0 ? id_combustivel.join(",") : "",
            id_loja: id_loja.length > 0 ? id_loja.join(",") : "",
        },
        url: root_rel + "getData/getModelo",
        success: function (response) {
            selecionados = apagaLi(element, "id_modelo[]");

            const queryParams = getQueryParams(window.location.href, ["id_modelo[]"]);
            selecionadosQuery = queryParams["id_modelo[]"] ? queryParams["id_modelo[]"] : [];

            if (response.length > 0) {
                $.each(response, function (key, value) {
                    if (!(selecionados.indexOf(value.id_modelo) > -1)) {
                        let checked = selecionadosQuery.indexOf(value.id_modelo) > -1 ? 1 : 0;

                        element.append(novoLi(value.id_modelo, value.descricao, "modelo", "checkbox", "id_modelo[]", element, checked));
                    }
                });
            }
        },
    });
}

function novoLi(valor, texto, campo, tipoCampo, name, element, checked = 0) {
    var novoLi = document.createElement("li");
    novoLi.classList.add("list-group-item");
    novoLi.dataset.valor = valor;

    var novoCheckBox = document.createElement("input");
    novoCheckBox.type = tipoCampo;
    novoCheckBox.name = name;
    novoCheckBox.value = valor;
    novoCheckBox.onchange = function () {
        atualizarFiltro(element, campo);
    };
    novoCheckBox.classList.add("form-check-input");
    novoCheckBox.classList.add("me-1");
    novoCheckBox.id = campo + "_" + valor;

    if (checked == 1) {
        novoCheckBox.checked = true;
    }

    var novoLabel = document.createElement("label");
    novoLabel.innerHTML = texto;
    novoLabel.htmlFor = campo + "_" + valor;

    novoLi.append(novoCheckBox);
    novoLi.append(novoLabel);

    return novoLi;
}

function apagaLi(element, name) {
    arrayValues = [];

    li = element.find("li");

    li.each(function (index) {
        input = $(this).find('input[name="' + name + '"]:checked');
        if (input.length == 0) {
            $(this).remove();
        } else {
            arrayValues.push(input.val());
        }
    });

    return arrayValues;
}

function getQueryParams(url, campos) {
    const queryParams = {};
    const queryString = url.split("?")[1];

    if (queryString) {
        const pairs = queryString.split("&");

        for (const pair of pairs) {
            const [key, value] = pair.split("=");
            const decodedKey = decodeURIComponent(key);
            const decodedValue = decodeURIComponent(value.replace(/\+/g, " "));

            if (campos.includes(decodedKey)) {
                queryParams[decodedKey] = queryParams[decodedKey] || [];
                queryParams[decodedKey].push(decodedValue);
            } else {
                queryParams[decodedKey] = decodedValue;
            }
        }
    }

    return queryParams;
}

function atualizarFiltroMarca(
    element,
    ano_min = null,
    ano_max = null,
    valor_min = null,
    valor_max = null,
    id_cambio = array(),
    id_combustivel = array(),
    id_loja = array()
) {
    var allItems = document.querySelectorAll(".item-marcas");

    $.ajax({
        type: "GET",
        data: {
            ano_min: ano_min,
            ano_max: ano_max,
            valor_min: valor_min,
            valor_max: valor_max,
            id_cambio: id_cambio.length > 0 ? id_cambio.join(",") : "",
            id_combustivel: id_combustivel.length > 0 ? id_combustivel.join(",") : "",
            id_loja: id_loja.length > 0 ? id_loja.join(",") : "",
        },
        url: root_rel + "getData/getMarca",
        success: function (response) {
            // Obtém uma lista de id_marca da resposta
            var idMarcasNaResposta = response.map((item) => parseInt(item.id_marca));

            // Itera sobre os itens e adiciona/remova a classe 'disabled' conforme necessário
            allItems.forEach(function (item) {
                var marcaId = item.dataset.marca_id;

                if (!idMarcasNaResposta.includes(parseInt(marcaId))) {
                    //console.log("Marca " + marcaId + " não está na resposta");
                    item.classList.add("disabled");
                } else {
                    //console.log("Marca " + marcaId + " está na resposta");
                    item.classList.remove("disabled");
                }
            });
        },
    });
}

function initSlideAnoVeiculo(anoveiculoMin, anoveiculoMax, selectedAnoMin, selectedAnoMax) {
    [...document.querySelectorAll(".anoveiculo")].forEach(function (elemento) {
        if (elemento.noUiSlider) {
            elemento.noUiSlider.destroy();
        }

        noUiSlider.create(elemento, {
            range: {
                min: anoveiculoMin,
                max: anoveiculoMax,
            },
            step: 1,
            start: [selectedAnoMin || anoveiculoMin, selectedAnoMax || anoveiculoMax],
            margin: 0,
            connect: true,
            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return parseInt(value);
                },
            },
            behaviour: "tap-drag",
            //    tooltips: true
        });
        elemento.noUiSlider.on("update", function (values, handle) {
            $(".slider-ano_" + handle).val(values[handle]);
            $(".slider-ano-range").html(values.join(" até "));
        });
    });
}

function initSlideQuilometragemVeiculo(kmMin, kmMax) {
    kmMaxLabel = "> " + kmMax.toLocaleString("pt-BR");

    [...document.querySelectorAll(".kmveiculo")].forEach(function (elemento) {
        noUiSlider.create(elemento, {
            range: {
                min: kmMin,
                max: kmMax,
            },
            step: 5000,
            start: [kmMin, kmMax],
            margin: 0,
            connect: true,
            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return parseInt(value);
                },
            },
            behaviour: "tap-drag",
            //    tooltips: true
        });
        elemento.noUiSlider.on("update", function (values, handle) {
            $(".slider-km_" + handle).val(values[handle]);
            values[0] = values[0].toLocaleString("pt-BR");
            if (values[1] == kmMax) {
                values[1] = kmMaxLabel;
            } else {
                values[1] = values[1].toLocaleString("pt-BR");
            }
            $(".slider-km-range").html(values.join(" até "));
        });
    });
}

function initSlideValorVeiculo(valorMin, valorMax) {
    valorMaxLabel = " R$ " + valorMax.toLocaleString("pt-BR");

    [...document.querySelectorAll(".valorveiculo")].forEach(function (elemento) {
        noUiSlider.create(elemento, {
            range: {
                min: [valorMin],
                max: [valorMax],
            },
            step: 5000,

            start: [valorMin, valorMax],
            connect: true,

            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return parseInt(value);
                },
            },

            behaviour: "tap-drag",
            //    tooltips: true
        });
        elemento.noUiSlider.on("update", function (values, handle) {
            $(".slider-valor_" + handle).val(values[handle]);
            values[0] = "R$ " + values[0].toLocaleString("pt-BR");
            if (values[1] == valorMax) {
                values[1] = valorMaxLabel;
            } else {
                values[1] = "R$ " + values[1].toLocaleString("pt-BR");
            }
            $(".slider-valor-range").html(values.join(" até "));
        });
    });
}

$(".clique-menu").click(function () {
    $(".menu-mobile").slideToggle();
    $(".outros-botoes").slideToggle();
});

if ($(".car-right-box").length > 0) {
    $(window).scroll(function () {
        var vpWidth = $(document).width();
        if (vpWidth > 1200) {
            var winTop = $(window).scrollTop();
            var pageContentTop = $(".box-interna-carro").offset().top - $("header").outerHeight() + $(".gallery-medias").outerHeight() - 20;

            var mainH = $(".box-interna-carro").height();
            var sidebarH = $(".car-right-box").height();

            var mainSideDiff = mainH - sidebarH + 20; // 20 = main margin-bottom
            var stopPointBottom = mainSideDiff;

            var footer = $(".box-assinatura").height() + $(".full-map-info").height() + $(".maps-full").height();

            if (winTop > pageContentTop && winTop < stopPointBottom) {
                var diff = winTop - pageContentTop;
                //          diff = diff + $("header").outerHeight();
                if (diff < 0) {
                    diff = 0;
                }
                $(".car-right-box").css("top", diff); // 10 = to give some space from top
            } else if (winTop > stopPointBottom) {
                $(".car-right-box").css("bottom", footer);
            } else {
                $(".car-right-box").css("top", 0);
            }
        }
    });
}

function initSlideQuilometragemVeiculo(kmMin, kmMax) {
    kmMaxLabel = "> " + kmMax.toLocaleString("pt-BR");

    [...document.querySelectorAll(".kmveiculo")].forEach(function (elemento) {
        noUiSlider.create(elemento, {
            range: {
                min: kmMin,
                max: kmMax,
            },
            step: 5000,
            start: [kmMin, kmMax],
            margin: 0,
            connect: true,
            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return parseInt(value);
                },
            },
            behaviour: "tap-drag",
            //    tooltips: true
        });
        elemento.noUiSlider.on("update", function (values, handle) {
            $(".slider-km_" + handle).val(values[handle]);
            values[0] = values[0].toLocaleString("pt-BR");
            if (values[1] == kmMax) {
                values[1] = kmMaxLabel;
            } else {
                values[1] = values[1].toLocaleString("pt-BR");
            }
            $(".slider-km-range").html(values.join(" até "));
        });
    });
}

function initSlideValorVeiculo(valorMin, valorMax) {
    valorMaxLabel = " R$ " + valorMax.toLocaleString("pt-BR");

    [...document.querySelectorAll(".valorveiculo")].forEach(function (elemento) {
        noUiSlider.create(elemento, {
            range: {
                min: [valorMin],
                max: [valorMax],
            },
            step: 5000,

            start: [valorMin, valorMax],
            connect: true,

            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return parseInt(value);
                },
            },

            behaviour: "tap-drag",
            //    tooltips: true
        });
        elemento.noUiSlider.on("update", function (values, handle) {
            $(".slider-valor_" + handle).val(values[handle]);
            values[0] = "R$ " + values[0].toLocaleString("pt-BR");
            if (values[1] == valorMax) {
                values[1] = valorMaxLabel;
            } else {
                values[1] = "R$ " + values[1].toLocaleString("pt-BR");
            }
            $(".slider-valor-range").html(values.join(" até "));
        });
    });
}

function openMenu() {
    $(".btn-menu-mobile").addClass("open");
    $(".menu-top").addClass("menu-show");
    $("body").addClass("block-overflow");
    $(".menu-overlay").addClass("show");
}

function closeMenu() {
    $(".btn-menu-mobile").removeClass("open");
    $(".menu-top").removeClass("menu-show");
    $("body").removeClass("block-overflow");
    $(".menu-overlay").removeClass("show");
}

function clickBanner(id_banner) {
    $.ajax({
        url: root_rel + "lua4auto/banners/click/" + id_banner,
        type: "get",
    });
}

function startLoading() {
    $(".loading-wrapper").stop(true, true).fadeIn();
}

function stopLoading() {
    $(".loading-wrapper").stop(true, true).fadeOut("fast");
}

function sendGAEvent(action, category, label) {
    if (typeof gtag !== "undefined") {
        console.log(action + "-" + category + "-" + label);
        gtag("event", action, { event_category: category, event_label: label });
    }
}

function refreshPage(atributo, valor) {
    var hash = {};
    var parser = document.createElement("a");

    parser.href = window.location.href;
    var parameters = parser.search.split(/\?|&/);
    for (var i = 0; i < parameters.length; i++) {
        if (!parameters[i]) continue;

        var ary = parameters[i].split("=");
        hash[ary[0]] = ary[1];
    }

    hash[atributo] = valor;

    var list = [];
    Object.keys(hash).forEach(function (key) {
        list.push(key + "=" + hash[key]);
    });

    parser.search = "?" + list.join("&");
    window.location.href = parser.href;
}

function carregarModelos(id_marca, $this) {
    console.log("URL", root_rel + "lua4auto/seminovosmodelos/busca/" + id_marca);

    if (id_marca != "" && id_marca > 0) {
        $.ajax({
            url: root_rel + "lua4auto/seminovosmodelos/busca/" + id_marca,
            type: "get",
            beforeSend: function () {
                $this.closest("form").find(".select-modelo").html('<option value="0">Selecione primeiro uma marca</option>');
                startLoading();
            },
            dataType: "json",
            success: function (json) {
                var html = '<option value="">Selecione um modelo</option>';
                $.each(json, function (key, value) {
                    if ($this.hasClass("marca-busca")) {
                        if (value.qtd_carros > 0) {
                            html += '<option value="' + value.id_modelo + '">' + value.descricao + "</option>";
                        }
                    } else {
                        html += '<option value="' + value.id_modelo + '">' + value.descricao + "</option>";
                    }
                });
                $this.closest("form").find(".select-modelo").html(html);
                $this.closest("form").find(".select-modelo").attr("disabled", false);
            },
            complete: function () {
                stopLoading();
            },
        });
    }
    /* atualizarAno(id_marca);

        var allItems = document.querySelectorAll(".item-marcas");
    
        allItems.forEach(function (item) {
            item.classList.remove("selected");
        });
    
        if (element !== undefined && element !== null) {
            element.classList.add("selected");
        }
    
        var formElement = element.parentNode;
        while (formElement.tagName !== "FORM" && formElement.tagName !== "BODY") {
            formElement = formElement.parentNode;
        }
    
        var selectModeloElement = formElement.querySelector(".select-modelo");
    
        if (id_marca != "" && id_marca > 0) {
            $.ajax({
                url: root_rel + "lua4auto/seminovosmodelos/busca/" + id_marca + "/0/1",
                type: "get",
                beforeSend: function () {
                    removerTodasAsOpcoes(selectModeloElement);
                    var newOption = document.createElement("option");
                    newOption.text = "Selecione primeiro uma marca";
                    newOption.value = "0";
                    selectModeloElement.add(newOption);
                    startLoading();
                },
                dataType: "json",
                success: function (json) {
                    removerTodasAsOpcoes(selectModeloElement);
                    var newOption = document.createElement("option");
                    newOption.text = "Selecione um modelo";
                    newOption.value = "0";
                    selectModeloElement.add(newOption);
                    $.each(json, function (key, value) {
                        var newOption = document.createElement("option");
                        newOption.text = value.descricao;
                        newOption.value = value.id_modelo;
                        selectModeloElement.add(newOption);
                    });
                },
                complete: function () {
                    stopLoading();
                },
            });
        } */
}

function removerTodasAsOpcoes(element) {
    // Remove todas as opções do <select>
    while (element.options.length > 0) {
        element.remove(0);
    }
}

function deleteCookies() {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase =
                encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
            var p = location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            }
            d.shift();
        }
    }
}
function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}
function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

//Animação cards

document.addEventListener("DOMContentLoaded", function () {
    //console.log("DOM totalmente carregado e analisado"); // Verifica se o DOM foi carregado

    const cards = document.querySelectorAll(".card-carro");
    //console.log(`Número de cards encontrados: ${cards.length}`); // Verifica quantos cards foram encontrados

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    //console.log(`O card entrou na tela: `, entry.target); // Confirma que o card está visível
                    entry.target.classList.add("card-visible");
                    //console.log("Classe 'card-visible' adicionada"); // Confirma que a classe foi adicionada
                    observer.unobserve(entry.target); // Remove o observador
                    //console.log("Observador removido para o card: ", entry.target); // Confirma que o observador foi removido
                } else {
                    //console.log(`O card saiu da tela: `, entry.target); // Exibe quando um card sai da área visível
                }
            });
        },
        {
            threshold: 0.1, // Inicia o efeito quando 10% do card está visível
        }
    );

    cards.forEach((card) => {
        observer.observe(card);
        //console.log("Observando o card: ", card); // Confirma que o observador foi aplicado a cada card
    });

    //Animação botão whatsapp fixo----------------------------------------------------------------------------------

    const whatsappIcon = document.querySelector(".whatsapp-fixed");

    setInterval(() => {
        whatsappIcon.classList.add("pulsar");

        // Remove a classe após a animação terminar (ajuste de acordo com a duração da animação)
        setTimeout(() => {
            whatsappIcon.classList.remove("pulsar");
        }, 1000); // Duração da animação de pulsação (1s no exemplo acima)
    }, 7000); // 7 segundos de intervalo
});
